<template >
  <v-flex md12 xs11>
    <material-card class="mx-4" color="primary" title="Lista de medicamentos">
      <v-card-title>
        <v-text-field
          md8
          v-model="medicinesTable.searchTerm"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :rows-per-page-items="rowsPerPageItems"
        :search="medicinesTable.searchTerm"
        :headers="headers"
        :items="dataMedicines"
      >
        <template slot="headerCell" slot-scope="{ header }">
          <span class="subheading font-weight-light text-primary" v-text="header.text"/>
        </template>
        <template v-slot:items="props">
          <td>{{ props.item.orderCode }}</td>
          <td>{{ props.item.plu }}</td>
          <td>{{ props.item.description }}</td>
          <td>{{ props.item.count }}</td>
          <td>{{ props.item.nextShippingDate }}</td>
          <td>
            <v-btn
              @click="deleteMedicinesList(props.item.orderCode)"
              :icon="true"
              round
              small
              color="red lighten-2"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </material-card>
  </v-flex>
</template>

<script>
export default {
  components: {},
  props: {
    deleteMedicinesList: {
      type: Function,
      default: () => {}
    },
    dataMedicines: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    medicinesTable: {
      searchItems: 0,
      searchTerm: ""
    },
    rowsPerPageItems: [5, 10, 15, 20],
    addM: false,
    headers: [
      {
        sortable: false,
        text: "#Order",
        value: "orderCode",
        align: "left"
      },
      {
        sortable: false,
        text: "PLU",
        value: "plu",
        align: "left"
      },
      {
        sortable: false,
        text: "Descipción",
        value: "description",
        align: "left"
      },
      {
        sortable: false,
        text: "Cantidad",
        value: "count",
        align: "left"
      },
      {
        sortable: false,
        text: "Fecha próxima dispensación",
        value: "nextShippingDate",
        align: "left"
      },
      {
        sortable: false,
        text: "Acciones",
        value: "actions",
        align: "left"
      }
    ],
    items: []
  })
};
</script>
